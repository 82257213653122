<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="createGroup()">新增</a-button>
    </div>
    <a-table
      rowKey="key"
      :columns="columns"
      v-if="treeData"
      :default-expand-all-rows="true"
      :data-source="treeData"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action' && record.group_id > 0">
          <span>
            <a @click="updateGroup(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="deleteGroupConfirm(record)">删除</a>
          </span>
        </template>
        <template v-if="column.dataIndex === 'group_id'">
          <span v-if="record.group_id > 0"> {{ record.group_id }} </span
          ><span v-else></span>
        </template>
      </template>
    </a-table>
  </div>

  <a-modal
    v-model:visible="create_visible"
    title="新增分组"
    @ok="handleCreateSubmit"
    :width="500"
  >
    <a-form ref="formRef" v-bind="layout" :model="formCreateState">
      <a-form-item has-feedback label="文件名称" name="name">
        <a-input
          v-model:value="formCreateState.name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="上级分组" name="parent_id">
        <a-tree-select
          v-model:value="formCreateState.parent_id"
          placeholder="请选择上级分组"
          allow-clear
          tree-default-expand-all
          :tree-data="treeData"
          :field-names="{
            children: 'children',
            label: 'name',
            key: 'group_id',
            value: 'group_id',
          }"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item has-feedback label="排序" name="sort">
        <a-input-number v-model:value="formCreateState.sort" />
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
    v-model:visible="update_visible"
    title="编辑分组"
    @ok="handleUpdateSubmit"
    :width="500"
  >
    <a-form ref="formRef" v-bind="layout" :model="formUpdateState">
      <a-form-item has-feedback label="文件名称" name="name">
        <a-input
          v-model:value="formUpdateState.name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="上级分组" name="parent_id">
        <a-tree-select
          v-model:value="formUpdateState.parent_id"
          placeholder="请选择上级分组"
          allow-clear
          tree-default-expand-all
          :tree-data="treeData"
          :field-names="{
            children: 'children',
            label: 'name',
            key: 'group_id',
            value: 'group_id',
          }"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item has-feedback label="排序" name="sort">
        <a-input-number v-model:value="formUpdateState.sort" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { Modal, message } from "ant-design-vue";
import * as Api from "@/addons/storage/api";
export default defineComponent({
  setup() {
    const treeData = ref();

    // 获取分组数据
    const getGroupList = () => {
      Api.group_list().then((res) => {
        console.log(res);
        treeData.value = res.data.list;
      });
    };

    onMounted(() => {
      getGroupList();
    });

    const create_visible = ref(false);

    const formCreateState = reactive({
      group_id: 0,
      name: "",
      parent_id: 0,
      sort: 0,
    });

    //添加
    const createGroup = () => {
      formCreateState.group_id = 0;
      formCreateState.name = "";
      formCreateState.parent_id = "0";
      formCreateState.sort = 0;

      create_visible.value = true;
    };

    const handleCreateSubmit = () => {
      Api.create_group(formCreateState).then((res) => {
        if (res.status === 200) {
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        create_visible.value = false;
        getGroupList();
      });
    };

    const update_visible = ref(false);

    const formUpdateState = reactive({
      group_id: 0,
      name: "",
      parent_id: "0",
      sort: 0,
    });

    // 编辑
    const updateGroup = (info) => {
      formUpdateState.group_id = info.group_id;
      formUpdateState.name = info.name;
      formUpdateState.parent_id = info.parent_id;
      formUpdateState.sort = info.sort;
      update_visible.value = true;
    };

    // 提交更新
    const handleUpdateSubmit = () => {
      Api.update_group(formUpdateState).then((res) => {
        if (res.status === 200) {
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        update_visible.value = false;
        getGroupList();
      });
    };

    const deleteGroupConfirm = (info) => {
      Modal.confirm({
        title: "确定要删除【" + info.name + "】吗?",
        content: "删除后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.delete_group({ group_id: info.group_id }).then((res) => {
            if (res.status === 200) {
              message.success(res.message);
            }
            getGroupList();
          });
        },
      });
    };

    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };

    return {
      treeData,
      layout,
      create_visible,
      update_visible,
      formUpdateState,
      formCreateState,
      createGroup,
      updateGroup,
      handleCreateSubmit,
      handleUpdateSubmit,
      deleteGroupConfirm,
      columns: [
        {
          title: "分组名称",
          dataIndex: "name",
          key: "name",
          width: "60%",
        },
        {
          title: "分组ID",
          dataIndex: "group_id",
          key: "group_id",
          width: "20%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "20%",
        },
      ],
    };
  },
});
</script>